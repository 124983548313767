import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Card, Table, Stack, Paper, Avatar, Button, Popover, TableRow, MenuItem, TableBody, TableCell, Container, Typography, TableContainer, TablePagination } from '@mui/material';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { useDispatch, useSelector } from 'react-redux';
import { image_base_url } from 'src/services/endpoints';
import { setLoader } from 'src/redux/loader';
import { setToast } from 'src/redux/toast';
import { getAllUsers } from 'src/redux/users';
import { useLocation } from 'react-router-dom';
import { getTeamsListThunk, exportTeamScore } from 'src/redux/teams';
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import moment from 'moment'
import hit from 'src/services/manager';
import RankingsModal from 'src/components/ranking';
import FilterDate from 'src/components/FilterDate';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'Total Admins', label: 'Total Admins', alignRight: false },
  { id: 'Total Members', label: "Total Members", alignRight: false },
  { id: 'Created_y', label: "Created By", alignRight: false },
  { id: 'Scoring Opportunities(%)', label: "Scoring Opportunities(%)", alignRight: false },
  { id: 'Avg Scores', label: "Avg Scores", alignRight: false },
  { id: 'action', label: "Action", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => (_user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 || _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1));
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);
  const user = useSelector(state => state.auth)?.user
  const navs = useSelector(state => state.navs)?.write
  const [page, setPage] = useState(0);
  const dispatch = useDispatch()
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [selectedTId, setSelectedTId] = useState(null);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const teams = useSelector(state => state.teams)?.list
  const scoreData = useSelector(state => state.teams)?.scoreData
  const [allTeams, setAllTeams] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [csvData, setCsvData] = useState([])
  const location = useLocation()
  const startSerialNumber = page * rowsPerPage + 1;
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allTeams?.length) : 0;
  const filteredUsers = applySortFilter(allTeams, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && !!filterName;


  const [selectedData, setSelectedData] = useState({
    status: 0,
    _id: null
  })

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allTeams?.map((n) => n?.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const deleteUser = async () => {
    dispatch(setLoader(true))
    let res = await hit("admins/users/" + selectedData?._id, "delete",)
    if (!res.err) {
      dispatch(getAllUsers())
    } else {
      dispatch(setToast({ show: true, message: res.msg, severity: "error" }))
    }
    dispatch(setLoader(false))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const exportToCSV = (teamid, dateRanges, type) => {
    let startDate = null
    let endDate = null
    if (type == "date") {
      startDate = dateRanges[0]
      endDate = dateRanges[1]
    }
    dispatch(exportTeamScore(teamid, startDate, endDate))
    // let dupScores = scores?.filter(x => {
    //   if (!(x.groups?.some(group => group == teamid))) {
    //     return false;
    //   }
    //   if (type == "date") {
    //     let firstDate = dateRanges[0]
    //     let secondDate = dateRanges[1]
    //     if (x?.type == 3) {
    //       if (moment(firstDate).toDate() <= moment(x?.updatedAt).toDate() && moment(secondDate) >= moment(x?.updatedAt).toDate()) {
    //         return true
    //       }
    //       return false
    //     } else {
    //       if (moment(firstDate).toDate() <= moment(x?.createdAt).toDate() && moment(secondDate) >= moment(x?.createdAt).toDate()) {
    //         return true
    //       }
    //       return false
    //     }
    //   }
    //   return true
    // })
    // let dupPosts = posts.filter(x => (x.groups?.map(g => g?._id)?.includes(teamid))).filter(x => {
    //   if (type == "date") {
    //     let firstDate = dateRanges[0]
    //     let secondDate = dateRanges[1]
    //     if (moment(firstDate).toDate() <= moment(x?.scoreStartTime).toDate() && moment(secondDate) >= moment(x?.scoreStartTime).toDate()) {
    //       return true
    //     }
    //     return false
    //   }
    //   return true
    // })
    // let manualsCats = dupScores?.filter(x => x.type == 4 && x?.manualId?._id).map(x => x?.manualId?.category)
    // let categorys = [...new Set([...dupPosts.filter(x => (x.groups?.map(g => g?._id)?.includes(teamid) && x.category != "")).map(x => x.category), ...manualsCats, "Quiz"])]
    // let totalScoringPostIndiPoints = dupPosts.filter(x => x.groups?.map(g => g?._id)?.includes(teamid))
    // let tsPoints = 0
    // for (let p of totalScoringPostIndiPoints) {
    //   if (p?.scoreBy?.includes("likes")) {
    //     tsPoints += p?.scorePoint
    //   }
    //   if (p?.scoreBy?.includes("verification")) {
    //     tsPoints += (p?.scorePoint1 * p?.scoreCount)
    //   }
    //   if (p?.scoreBy?.includes("geo")) {
    //     tsPoints += p?.scorePoint2
    //   }
    //   if (p?.postType1 == "quiz") {
    //     for (let q of p?.quizData) {
    //       tsPoints += q?.point
    //     }
    //   }
    // }
    // let cs = []
    // for (let i of categorys) {
    //   let postsl = dupPosts.filter(x => (x.category == i || (x?.postType1 == "quiz" && i == "Quiz"))).length
    //   cs.push(i)
    //   for (let j = 0; j < postsl; j++) {
    //     cs.push("")
    //   }
    // }
    // const ws = XLSX.utils.json_to_sheet([]);
    // XLSX.utils.sheet_add_aoa(ws, [["Name (Email)", "Gender", "Grade", "Scoring Opportunities(%)", "Average Score", "Total Points", ...cs]], { origin: "A1" });
    // let merge = []
    // let prevCol = 6
    // for (let i = 0; i < categorys.length; i++) {
    //   let postsl = dupPosts.filter(x => (x.category == categorys[i] || (x?.postType1 == "quiz" && categorys[i] == "Quiz"))).length
    //   merge.push({
    //     s: { r: 0, c: prevCol },
    //     e: { r: 0, c: prevCol + postsl },
    //   })
    //   prevCol = prevCol + postsl + 1
    // }
    // ws["!merges"] = merge
    // let A2 = ["", "", "", "", "", ""]
    // for (let i of categorys) {
    //   let ps = dupPosts.filter(x => x.category == i || (x?.postType1 == "quiz" && i == "Quiz")).map(x => {
    //     if (x?.postType1 == "quiz") {
    //       return x?.quizTitle + " (Quiz)"
    //     }
    //     return x?.txt
    //   })
    //   for (let p of ps) {
    //     A2.push(p)
    //   }
    //   A2.push("Manual Points")
    // }
    // ws['!rows'] = A2
    // const cellRef = XLSX.utils.encode_cell({ c: 0, r: 1 });
    // XLSX.utils.sheet_add_aoa(ws, [A2], { origin: cellRef });
    // let i = 2
    // for (let m of members) {
    //   const cellRef = XLSX.utils.encode_cell({ c: 0, r: i });
    //   let v = []
    //   v.push(`${m?.firstName} ${m?.lastName} (${m?.email})`)
    //   v.push(m?.gender ?? "male")
    //   v.push(m?.grade)
    //   let pScores = []
    //   let totalScores = 0
    //   for (let ca of categorys) {
    //     let ps = dupPosts.filter(x => (x.category == ca || (x?.postType1 == "quiz" && ca == "Quiz")))
    //     for (let p of ps) {
    //       let pScore = 0
    //       let uscores = dupScores.filter(x => (x?.user?._id == m?._id && x?.postId?._id == p._id && x?.groups?.includes(teamid)))
    //       for (let score of uscores) {
    //         pScore = pScore + score.point
    //       }
    //       totalScores = totalScores + pScore
    //       pScores.push(pScore)
    //     }
    //     let scs = dupScores.filter(x => x?.manualId?.category == ca && x?.user?._id == m?._id)?.filter(x => x?.groups?.includes(teamid))?.map(x => x.point)?.reduce((a, b) => a + b, 0)
    //     pScores.push(scs)
    //   }
    //   let unscores = Math.round((dupScores.filter(x => x?.user?._id == m?._id && x?.type == 4 && x?.groups?.includes(teamid)).map(x => x.point).reduce((a, b) => a + b, 0)))
    //   scoring opportunities
    //   v.push((Number.isNaN(Math.round((totalScores + unscores) * 100 / ((tsPoints + unscores) ?? 1))) ? "0" : Math.round((totalScores + unscores) * 100 / ((tsPoints + unscores) ?? 1))) + "%")
    //   v.push(Math.round((tsPoints + unscores) / ((totalScores + unscores) ?? 1))) //average
    //   v.push(totalScores + unscores) // total
    //   for (let ps of pScores) {
    //     v.push(ps) // manual
    //   }
    //   XLSX.utils.sheet_add_aoa(ws, [v], { origin: cellRef });
    //   i++
    // }
    // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // const data = new Blob([excelBuffer], { type: fileType });
    // FileSaver.saveAs(data, fileName + fileExtension);
  }

  useEffect(() => {
    if (scoreData) {
      const scorings = scoreData?.scoring ?? [];
      const ws = XLSX.utils.json_to_sheet([]);
      // Add headers to the worksheet
      XLSX.utils.sheet_add_aoa(ws, [
        ["Name (Email)", "Gender", "Grade", "Scoring Opportunities(%)", "Average Score", "Total Points", "Quiz Manual Points"]
      ], { origin: "A1" });
  
      // Add data rows to the worksheet
      let i = 2;
      for (let m of scorings) {
        const cellRef = XLSX.utils.encode_cell({ c: 0, r: i });
        let avg = Math.round(((m?.totalPoints ?? 0 + m?.manualPoints ?? 0) / (m?.quizPoints ?? 0 + m?.manualPoints ?? 0)) ?? 1)
        const row = [
          `${m?.userDetails?.firstName} ${m?.userDetails?.lastName} (${m?.userDetails?.email})`,
          m?.userDetails?.gender === "unknown" ? "Male" : m?.userDetails?.gender,
          m?.userDetails?.grade,
          `${(Number.isNaN(Math.round((m?.quizPoints + m?.manualPoints) * 100 / ((m?.totalPoints + m?.manualPoints) ?? 1))) ? "0" : Math.round((m?.quizPoints + m.manualPoints) * 100 / ((m?.totalPoints + m?.manualPoints) ?? 1)))}%`,
          avg == "Infinity" ? 0 : avg,
          m?.quizPoints + m?.manualPoints, // total points
          m?.manualPoints // manual points
        ];
        XLSX.utils.sheet_add_aoa(ws, [row], { origin: cellRef });
        i++;
      }
      // Calculate column widths
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const colWidths = data[0].map((_, colIndex) =>
        Math.max(...data.map(row => (row[colIndex] ? row[colIndex].toString().length : 0))) + 2
      );
      ws["!cols"] = colWidths.map(width => ({ wch: width }));
      // Create workbook and save as Excel file
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, new Date().getTime() + fileExtension);
    }
  }, [scoreData]);  

  useEffect(() => {
    let ft = []
    let ts = teams?.filter(x => x.type == "group")
    for (let t of ts) {
      if (user?.role == "user" || user?.role == "admin") {
        if (t?.admins?.includes(user?._id)) {
          ft.push(t)
        }
      } else {
        ft.push(t)
      }
    }
    let newCSV = []
    newCSV.push(['Sno', 'Name', 'Total Admins', 'Total Members', "Avg Scores", "Created At"])
    let i = 1
    for (let team of ft) {
      if (!team) {
        continue
      }
      const { name, admins, members, createdBy, createdAt, avg_score } = team;
      newCSV.push([i, name, admins.length, members.length, avg_score, moment(createdAt).format("DD/MM/YYYY")])
      i++
    }
    setCsvData(newCSV)
    setAllTeams(ft)
  }, [teams])

  useEffect(() => {
    dispatch(getTeamsListThunk())
  }, [location])

  return (
    <>
      <Helmet>
        <title> Teams | CultureCode </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Teams
          </Typography>
          <CSVLink style={{ color: "#0DB1D4" }} filename={"teams.csv"} data={csvData}>Download CSV Data</CSVLink>
        </Stack>
        <Card>
          <UserListToolbar placeholder={"Search Teams..."} typeFilter="teams" numSelected={selected.length} show={true} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={allTeams.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {allTeams?.map((row, index) => {
                    const { _id, name, admins, members, createdAt, profilePic, total_score, avg_score } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;
                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox" sx={{ paddingLeft: 3 }}>
                          {startSerialNumber + index}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={image_base_url + profilePic} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{admins?.length}</TableCell>
                        <TableCell align="left">{members.length}</TableCell>
                        <TableCell align="left">{moment(createdAt).format("LLLL")}</TableCell>
                        <TableCell align="left">
                          {Math.round(total_score)}
                        </TableCell>
                        <TableCell align="left">
                          {Math.round(avg_score)}
                        </TableCell>
                        <TableCell align="right" sx={{ display: "flex", flexDirection: "column-reverse", }}>
                          <Button
                            onClick={() => {
                              if (user?.role == "admin" || user?.role == "staff") {
                                if (user?.role == "admin") {
                                  setSelectedGroup(_id)
                                  setOpen2(true)
                                } else {
                                  if (navs.includes("teams")) {
                                    setSelectedGroup(_id)
                                    setOpen2(true)
                                  } else {
                                    dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))
                                  }
                                }
                              } else {
                                setSelectedGroup(_id)
                                setOpen2(true)
                              }
                            }}
                            sx={{ marginY: "5px" }} color={'info'} variant="contained">Rankings
                          </Button>
                          <Button onClick={() => {
                            if (user?.role == "admin" || user?.role == "staff") {
                              if (user?.role == "admin") {
                                setSelectedTId(_id)
                                setOpen1(true)
                              } else {
                                if (navs.includes("teams")) {
                                  setSelectedTId(_id)
                                  setOpen1(true)
                                } else {
                                  dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))
                                }
                              }
                            } else {
                              setSelectedTId(_id)
                              setOpen1(true)
                            }
                          }} sx={{ marginY: "5px" }} color={'info'} variant="contained" >Export Sheet</Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}>
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={allTeams.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}>
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      {
        open1 &&
        <FilterDate
          open={open1}
          setOpen={setOpen1}
          onSubmit={(value) => {
            if (value) {
              if (value[0] && value[1]) {
                if (moment(new Date(value[0])).format("YYYY-MM-DD HH:mm") != moment(new Date(value[1])).format("YYYY-MM-DD HH:mm")) {
                  exportToCSV(selectedTId, value, "date")
                  setOpen1(false)
                } else {
                  dispatch(setToast({ show: true, message: "Both date should be different!", severity: "error" }))
                }
              }
            }
          }}
          onAll={() => {
            exportToCSV(selectedTId, [], "all")
            setOpen1(false)
          }}
        />
      }
      <RankingsModal
        selectedGroupId={selectedGroup}
        open={open2}
        title="Rankings"
        setOpen={setOpen2}
      />
    </>
  );
}