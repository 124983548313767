import { createSlice } from '@reduxjs/toolkit'
import hit from 'src/services/manager'

const initialState = {
    list: [],
    teamData: null,
    scoreData: null
}

const ordersSlice = createSlice({
    name: "teams",
    initialState,
    reducers: {
        setTeamList: (state, action) => {
            state.list = action.payload
        },
        setTeamData: (state, action) => {
            state.teamData = action.payload
        },
        setScoreData: (state, action) => {
            state.scoreData = action.payload
        }
    }
})

export const { setTeamList, setTeamData, setScoreData } = ordersSlice.actions

export const getTeamsListThunk = () => {
    return async (dispatch) => {
        let res = await hit("/admins/teams", "get")
        if (!res.err) {
            dispatch(setTeamList(res?.data))
        }
    }
}

export const getTeamDetail = (id) => {
    return async (dispatch) => {
        let res = await hit(`/admins/team/${id}`, "get")
        if (!res.err) {
            dispatch(setTeamData(res?.data))
        }
    }
}

export const exportTeamScore = (id, startDate=null, endDate=null) => {
    return async (dispatch) => {
        let query = ''
        if(startDate && endDate) {
            query = `?start_date=${startDate}&end_date=${endDate}`
        }
        let res = await hit(`/admins/team/score/${id}${query}`, "get")
        if (!res.err) {
            dispatch(setScoreData(res?.data))
        }
    }
}

export default ordersSlice.reducer