import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { Avatar, Divider, Stack, Typography } from '@mui/material';
import { image_base_url } from 'src/services/endpoints';
import { getTeamDetail } from 'src/redux/teams';

export default function RankingsModal({ title, open, setOpen, selectedGroupId }) {
    const scores = useSelector(state => state.scores).list
    const teams = useSelector(state => state.teams)?.list
    const teamData = useSelector(state => state.teams)?.teamData
    const [data, setData] = useState([])
    const dispatch = useDispatch()
    
    useEffect(() => {
        if (selectedGroupId && open) {
            // call API to get team details
            dispatch(getTeamDetail(selectedGroupId))
        }
    }, [open, selectedGroupId, scores, teams])

    useEffect(() => {
        if(teamData) {
            let rankings = teamData?.ranking
            let users = rankings?.filter((r) => r?.role == "member")
            let admins = rankings?.filter((r) => r?.role == "admin")
            users?.sort((a, b) => b.points - a.points)
            admins?.sort((a, b) => b.points - a.points)
            let newData = [...admins, ...users]
            setData(newData)
        }
    },[teamData])

    return (
        <Dialog
            open={open}
            onClose={() => { setOpen(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {data?.map((x, i) => {
                    return (
                    <><div style={{ display: "flex", minWidth: "400px",alignItems:"center", marginTop: "10px" }}>
                        <Typography>{i + 1}.</Typography>
                        <Stack direction="row" alignItems="center" flex={1} marginLeft={"5px"} spacing={2}>
                            <Avatar alt={x?.firstName} src={image_base_url + x?.profilePic} />
                            <Typography variant="subtitle2" noWrap>
                                {x?.firstName}{x?.firstName==""?x?.email?.split("@")[0]:""}{x?.role == "admin"?" (Admin)":""}
                            </Typography>
                        </Stack>
                        <Typography>{x?.points}</Typography>
                    </div>
                    <Divider sx={{marginTop:"5px"}}/>
                    </>
                    )
                })}
            </DialogContent>
        </Dialog>
    );
}