import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar, useTheme } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthorize } from 'src/redux/auth';
import { setNavConfig, setReadNavData, setWriteNavData } from 'src/redux/navs';
import { useState } from 'react';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch()
  const isDesktop = useResponsive('up', 'lg');
  const theme = useTheme()
  const user = useSelector(state => state.auth?.user)
  const [navsConfig, setNavConfigs] = useState(navConfig)
  const navigate = useNavigate()

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  useEffect(() => {
    if (user?.accessLevel && user?.role == "staff") {
      let read = []
      let write = []
      let newConfig = []
      for (let i of navConfig) {
        let d = user?.accessLevel[`${i.v}`]
        if (d?.read) {
          newConfig.push(i)
          read.push(i.v)
        }
        if (d?.write) {
          write.push(i.v)
        }
      }
      setNavConfigs(newConfig)
      dispatch(setReadNavData(read))
      dispatch(setWriteNavData(write))
      dispatch(setNavConfig(newConfig))
    } else {
      if (user?.role == "admin") {
        setNavConfigs(navConfig)
        dispatch(setReadNavData([]))
        dispatch(setWriteNavData([]))
      } else if (user?.role) {
        setNavConfigs([
          navConfig[2]
        ])
        dispatch(setReadNavData([]))
        dispatch(setWriteNavData([]))
      }
    }
  }, [user])

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}>
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>
      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user?.role == "admin" ? "Admin" : (user?.role != "staff" ? user?.email : "Staff")}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>
      <NavSection data={navsConfig} />
      <Box sx={{ flexGrow: 1 }} />
      <Box 
        onClick={() => {
          localStorage.clear()
          localStorage.removeItem("@remember")
          dispatch(setAuthorize(false))
          navigate('/login')
        }} 
        sx={{ display: "flex", justifyContent: "center", cursor: 'pointer', background: theme.palette.primary.main, py: 2, mt: 6, alignItems: "center" }}>
          <img src='/assets/icons/logout.png' style={{ height: "21px" }} alt='logout' />
          <Typography sx={{ fontSize: "20px", ml: 1 }} color="white" >Logout</Typography>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}>
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}