// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
    v:"dashboard"
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
    v:"users"
  },
  {
    title: 'teams',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
    v:"teams"
  },
  {
    title: 'Pages',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
  v:"pages"
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: icon('ic_lock'),
    v: "settings"
  },
  {
    title: 'Contact Us',
    path: '/dashboard/supports',
    icon: icon('ic_help'),
    v: "supports"
  }
];
export default navConfig;