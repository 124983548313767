import {createSlice} from '@reduxjs/toolkit'
import hit from 'src/services/manager'

const initialState={
    list:[]
}

const ordersSlice=createSlice({
    name:"scores",
    initialState,
    reducers:{
        setScoreList:(state,action)=>{
            state.list=action.payload
        }
    }
})

export const {setScoreList}=ordersSlice.actions

export const getScoresListThunk=()=>{
    return async(dispatch)=>{
        let res=await hit("/admins/scores","get")
        if(!res.err){
            dispatch(setScoreList(res?.data))
        }
    }
}

export default ordersSlice.reducer